<template>
    <Teleport to=".cmp-darkmode">
        <label class="toggle" for="dkm">
            <input class="toggle__input" name="" type="checkbox" id="dkm" role="switch" @click="toggleDarkMode" :checked="darkMode">
            <div :aria-label="darkMode ? 'toggle dark mode off' : 'toggle dark mode on'" tabindex="0" class="toggle__fill" @keydown.enter.prevent="toggleDarkMode" @keydown.space.prevent="toggleDarkMode"></div>
        </label>
    </Teleport>
</template>

<script>
import { getCookiebyName } from '../../../../../../../dow-platform/ui.frontend/src/utils/cookies.js'
import CoreComponent from '../../../../../../../dow-platform/ui.frontend/src/dow-platform/components/core/CoreComponent.vue';

export default {
    extends: CoreComponent,
    data() {
        return {
            darkMode: null
        };
    },
    methods: {
        initTheme() {


            let darkMode = getCookiebyName('useDarkMode');

            if (darkMode != undefined) {
                this.darkMode = darkMode == "true";
            } else {
                this.darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches; 
                this.setCookie();
                this.setClass();
            }            
        },
        setCookie() {
            let cookieExpire = new Date() 
            cookieExpire.setFullYear(cookieExpire.getFullYear() + 5);
           
            this.$store.dispatch('browser/setBrowserCookie',{
                key: 'useDarkMode',
                value: this.darkMode,
                attributes: {
                    expires: cookieExpire.toUTCString()
                }
            });
        },
        toggleDarkMode() {
            this.darkMode = !this.darkMode;            
            this.setCookie();
            this.setClass();
            this.toggleForms();

            this.$store.dispatch('trackCmpInteraction', {
                event: 'cmp:click', 
                cmpId: this.cmpId
            })
        },
        setClass() {
            if (this.darkMode) {
                document.body.classList.add('tw-dark');
            } else {
                document.body.classList.remove('tw-dark');
            }
        },
        setForms() {
                document.querySelectorAll(".aemform iframe")?.forEach(frame => {
                    frame.addEventListener("load", () => {
                        if (this.darkMode) {
                            frame.contentDocument.body.classList.add("tw-dark");
                        } else {
                            frame.contentDocument.body.classList.remove("tw-dark");
                        }
                    })
            });
        },
        ToggleForms() {
                document.querySelectorAll(".aemform iframe")?.forEach(frame => {
                    if (this.darkMode) {
                            frame.contentDocument.body.classList.add("tw-dark");
                        } else {
                            frame.contentDocument.body.classList.remove("tw-dark");
                        }
            });
        }
    },

    created() {
        this.initTheme();
        this.setClass();
        this.setForms();
    },
}

</script>

<style lang="scss">
@import './dark-mode-toggle.scss';
</style>

